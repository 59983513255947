.popup-modal {
  z-index: 999999;
}
.btn-active {
  opacity: 0.6;
}
.profile-container {
  width: 60%;
  display: block;
  margin: 0 auto;
  background-color: white;
  padding: 50px 10px;
}
.name-container {
  padding: 20px;
  background-color: #eee;
}
.name {
  display: flex;
  justify-content: space-between;
}
.name button {
  background-color: #26bd68;
  color: #fff;
  padding: 5px 20px;
  outline: transparent;
  border: transparent;
}
.hero-profile-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.hero-profile-photo {
  width: 30%;
  position: relative;
}
.hero-other-user-profile-photo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.like-this-profile {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.like-this-profile-container {
  width: 20%;
}
.show-contact-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #26bd68, #066759);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show-contact-container .icon {
  font-size: 50px;
  border-radius: 100px;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #fff;
}
.show-contact-container .call-btn,
.show-contact-container .whatsapp-btn {
  background-color: #26bd68;
  color: #fff;
}
.hero-other-user-profile-photo .like-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.like-container .icon {
  color: #26bd68;
  font-size: 40px;
  scale: 1;
  cursor: pointer;
  transition: 0.3s all ease;
}
.like-container .icon:hover {
  scale: 1.1;
}
.hero-other-user-profile-photo .like-container:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.hero-other-profile-image-container {
  width: 30%;
  position: relative;
  height: 300px;
}
.hero-profile-photo img,
.hero-other-user-profile-photo img {
  cursor: pointer;
}

.hero-profile-photo .arrow,
.hero-other-user-profile-photo .arrow {
  position: absolute;
  bottom: 12%;
  padding: 5px;
  margin: 0;
  color: white;
}
.hero-profile-photo .arrow .icon,
.hero-other-user-profile-photo .arrow .icon {
  font-size: 30px;
  cursor: pointer;
}
.hero-profile-photo
  .arrow
  .icon:hover
  .hero-other-user-profile-photo
  .arrow
  .icon:hover {
  opacity: 0.8;
}
.hero-profile-photo .left-arrow,
.hero-other-user-profile-photo .left-arrow {
  left: 0;
}
.hero-profile-photo .right-arrow,
.hero-other-user-profile-photo .right-arrow {
  right: 0;
}
.hero-profile-photo img,
.hero-other-user-profile-photo img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.check-photos {
  background-color: #fff;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.hero-profile-data {
  width: 70%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.profile-cardd {
  padding: 0 20px;
}
.data {
  display: flex;
  flex-wrap: wrap;
}
.data-one,
.data-two {
  width: 50%;
  height: 100px;
  padding: 0 20px;
}
.data-one {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.data-title,
.data-answer {
  width: 50%;
}
.more-data {
  width: 100%;
  height: 150px;
}
.manage-profile span {
  color: #26bd68;
  text-decoration: underline !important;
}
.manage-profile-options .options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.options li {
  width: 33%;
}
.options li a {
  color: #333;
}

.about-btn {
  border-radius: 0;
  border: transparent;
  outline: transparent;
  padding: 10px 20px;
  background-color: #26bd68;
  color: white;
  opacity: 0.7;
}
.about-btn.active {
  opacity: 1;
}

/* About Myself */
.about-myself-container h6,
.about-myself-heading h6 {
  color: #066759;
  font-weight: bold;
}
.about-myself-heading {
  display: flex;
  justify-content: space-between;
}
.about-myself-heading span {
  width: 20%;
  text-align: end;
  cursor: pointer;
}
.about-myself-heading span .icon {
  color: #066759;
  font-size: 20px;
}
.user-answer {
  color: grey;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.user-answer-left {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.user-answer-right {
  padding-left: 10px;
}
.user-answer-left,
.user-answer-right {
  width: 50%;
}
.user-fields-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.user-fields-details .title,
.user-fields-details .ans {
  width: 50%;
}
.ans {
  color: black;
  font-weight: 500;
}

/* ================================= CONTACT CONTAINER  */
.user-contact-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.user-contact-container button {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  outline: transparent;
  border: transparent;
  padding: 8px 15px;
  border-radius: 100px;
  font-size: 12px;
}
.user-contact-container button .icon {
  font-size: 14px;
}
.user-contact-container .call-btn,
.user-contact-container .whatsapp-btn {
  background-color: #fff;
}
.user-contact-container .call-btn:hover,
.user-contact-container .whatsapp-btn:hover {
  background-color: #26bd68;
  color: #fff;
}

.contact-upgrade-modal {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
}
.contact-upgrade-modal.active {
  top: 0;
  left: 0;
}
.upgrade-container {
  background-color: #fff;
  overflow: hidden;
  background: linear-gradient(to right, #26bd68, #066759);
  padding: 40px;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.upgrade-container button {
  padding: 8px 15px;
  background-color: #fff;
  color: #333;
  outline: transparent;
  border: transparent;
  margin: 0 10px;
  border-radius: 100px;
  scale: 1;
}
.upgrade-container button:hover {
  scale: 1.01;
  opacity: 0.9;
}
.upgrade-profile {
  padding: 10px;
  background-color: #fff;
}
.upgrade-profile img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 100px;
}

@media screen and (max-width: 992px) {
  .profile-container {
    width: 100%;
    padding-bottom: 100px;
    padding-top: 0px;
  }
  .hero-profile-photo,
  .hero-other-user-profile-photo {
    width: 100%;
  }
  .hero-profile-data {
    width: 100%;
  }
  .data-one,
  .data-two {
    width: 100%;
    height: auto;
    padding: 0;
  }
  .data-one {
    border: 0;
  }
  .more-data {
    width: 100%;
    padding: 30px 0;
  }
  .options li {
    width: 50%;
  }
  .user-answer-left,
  .user-answer-right {
    width: 100%;
    border: 0;
  }
  .user-answer-right {
    padding-left: 0px;
  }
  .hero-other-profile-image-container {
    width: 100%;
  }
  .hero-other-user-profile-photo {
    gap: 0;
  }
  .hero-other-user-profile-photo .like-container {
    width: 100%;
    display: block;
    display: flex;
    padding: 10px;
    background-color: #26bd672b;
    border: 1px solid #26bd68;
  }
  .like-this-profile-container {
    width: 100%;
  }
  .upgrade-container {
    width: 80%;
    flex-direction: column;
  }
  .user-contact-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 0px;
  }
  .user-contact-container span {
    width: 25%;
  }
  .user-contact-container button {
    font-size: 10px;
    margin: 0 10px;
  }

  .show-contact-container {
    width: 100%;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
  }
  .show-contact-container .icon {
    font-size: 45px;
  }
  /* .image-modal-container .icon {
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
  } */
}
