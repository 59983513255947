.admin-edit-container {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  color: #333;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.edit-admin-user-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 30px;
}
.edit-admin-user-img img {
  width: 18%;
}
.edit-admin-user-form {
  width: 100%;
}
.modal-body img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .edit-admin-user-img {
    width: 100%;
    border: 0;
    margin-bottom: 20px;
  }
  .edit-admin-user-form {
    width: 100%;
  }
  .edit-admin-user-img {
    overflow-x: scroll;
    width: 100%;
  }
  .edit-admin-user-img img {
    width: 100%;
  }
}
