/* FILTER  */
.all-user-container .icon {
  font-size: 70px;
}
.form-fields-filter select {
  border: 2px solid #26bd68;
  border-radius: 0px;
}
.marital-status {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
}
.marital-status span {
  color: #333;
  border: 1px solid #26bd68;
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
}
.marital-status span:hover {
  background-color: #26bd68;
  color: #fff;
}
.marital-status span.active {
  background-color: #26bd68;
  color: #fff;
}
.filter-form {
  height: auto;
  position: sticky !important;
  top: 0 !important;
}
.filter-form h5 {
  font-size: 15px;
  display: block;
  width: 100%;
}
.horizontal-slider {
  width: 100%;
  height: 50px;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 10px;
}
.example-track.example-track-0 {
  background-color: #eee;
}
.example-track.example-track-1 {
  background-color: #26bd68;
}
.example-track.example-track-2 {
  background-color: #eee;
}
.horizontal-slider .example-thumb {
  top: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 0.9rem;
  background-color: #066759;
  color: #fff;
  cursor: pointer;
  border: 2px solid #066759;
  box-sizing: border-box;
}
/* FILTER  */
.new-matches-container {
  position: relative;
  overflow: hidden;
  padding: 50px 50px;
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
  flex-wrap: wrap;
}
.filter-container {
  width: 20%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #26bd68;
  height: 650px;
  min-height: 100vh;
  height: auto;
  position: sticky;
  top: 0;
}
.all-user-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.pagination-container {
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
}
.new-matches-users-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.new-match-users {
  padding: 10px;
  width: 48%;
  height: 250px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  scale: 1;
  transition: 0.3s all ease;
  margin-bottom: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.user-loading {
  width: 48%;
  height: 220px;
}
.new-match-users:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
}
.new-match-users:hover {
  scale: 1.01;
}
.new-match-user-image {
  width: 40%;
  height: 100%;
  overflow: hidden;
}
.new-match-user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}
.new-match-user-details {
  width: 60%;
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-match-user-details.premium {
  background-color: #f0fff5;
}
.new-match-user-details h6 {
  font-size: 16px;
  font-weight: bold;
}
.user-details {
  font-weight: 400;
  font-size: 14px;
  margin: 20px 0;
}
.id-verified,
.paid-member {
  color: #26bd68;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.id-verified .icon,
.paid-member .icon {
  font-size: 18px;
  color: #26bd68;
  margin-right: 2px;
}
.paid-member {
  color: #c89310;
}
.paid-member .icon {
  color: #c89310;
  margin-left: 8px;
}
.user-data span {
  font-size: 18px;
}
.view-profile-btn {
  background-color: #26bd68;
  color: #fff;
  padding: 8px 15px !important;
  outline: transparent;
  border: transparent;
  border-radius: 4px;
  &:hover {
    background-color: #066759;
  }
}
.user-data {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.new-match-left-data,
.new-match-right-data {
  width: 50%;
}

.filter-close .icon {
  color: #26bd68;
  font-size: 30px;
}

/* ====================== FILTER  */
.filter-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.new-match-filter-btn {
  padding: 8px 20px;
  outline: transparent;
  border: transparent;
  background-color: #26bd68;
  color: #fff;
}
.no-result-container img {
  width: 50%;
}

.mobile-filter {
  display: none;
}
/* ====================== FILTER  */

@media screen and (max-width: 992px) {
  .mobile-filter {
    display: block;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    background-color: #26bd68;
    color: #fff;
    border-radius: 10px;
    height: 45px;
    padding: 20px 10px;
  }
  .sticky {
    position: fixed;
    top: -20px;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); /* Optional: Add a shadow for a visual effect */
    border-radius: 0px;
  }
  .mobile-filter-form {
    width: 100%;
    height: 100vh;
    display: flex;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: white;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 9999999999999;
    transition: 0.2s all ease;
  }
  .mobile-filter-form.active {
    left: 0;
  }
  .filter-close {
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .mobile-filter-form .icon {
    color: #26bd68;
    font-size: 40px;
  }
  .new-matches-container {
    padding: 10px;
    padding-bottom: 100px;
  }
  .all-user-container {
    width: 100%;
  }
  .new-match-users {
    width: 100%;
  }
  .filter-container {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
  }
  .filter-form {
    width: 90%;
  }
  .no-result-container img {
    width: 80%;
  }
  /* mobile card */
  .new-match-users {
    height: auto;
  }
  .new-match-user-image {
    width: 100%;
    height: 280px;
  }
  .user-loading {
    width: 100%;
  }
  .new-match-user-details {
    width: 100%;
    padding: 20px;
  }
}
